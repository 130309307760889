import { getDefaultTrafficList } from 'api/report';
import Pagination from '@/components/pagination';
import GroupSelect from '@/components/GroupSelect';
import dateUtils from '@/utils/dateutils.js';
import { myMixin } from '@/mixins/mixins.js';
import optionData from '@/assets/js/optionData';
import { mapActions, mapState } from 'vuex';
import { filterObject } from 'utils/utils';

export default {
  mixins: [myMixin],
  components: {
    Pagination,
    GroupSelect,
  },
  data() {
    return {
      pageSize: 20,
      currentPage: 1,
      offerChecked: false,
      collFlag: false,
      affSubsChecked: false,
      monthChecked: false,
      dateChecked: true,
      hourChecked: false,
      defaultTrafficList: [],
      pageCount: null,
      totalCount: null,
      startToEndDate: '',
      listLoading: false,
      targetOptions: [
        {
          label: 'ALL',
          value: '',
        },
        {
          label: 'BlackAff',
          value: 'BlackAff',
        },
        {
          label: 'BadGidIdfa',
          value: 'BadGidIdfa',
        },
        {
          label: 'PausedOffer',
          value: 'PausedOffer',
        },
        {
          label: 'BlackAffSub',
          value: 'BlackAffSub',
        },
        {
          label: 'WrongCountry',
          value: 'WrongCountry',
        },
      ],
      timezoneOption: optionData.timezoneOption,
      affiliateIdArray: [],
      defaultTraffic: {
        affiliateIds: null,
        offerIds: null,
        fromDate: null,
        toDate: null,
        timezone: 0,
        columns: [],
        defType: '',
      },
    };
  },
  computed: {
    ...mapState('affiliate', {
      affiliatesGroupList: (state) => state.affiliatesGroupList,
      affiliateLoading: (state) => state.affiliateLoading,
    }),
  },
  mounted() {
    //默认选择
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() + 3600 * 1000 * 24);
    this.startToEndDate = [];
    this.startToEndDate[0] = dateUtils.date2StrYMD(end);
    this.startToEndDate[1] = dateUtils.date2StrYMD(start);

    this.getAffiliateList();
  },
  methods: {
    ...mapActions('affiliate', ['getAffiliateList']),
    getdefaultTrafficReportParam() {
      let param = {
        ...this.copy(this.defaultTraffic),
        page: this.currentPage,
        pageSize: this.pageSize,
      };

      if (this.offerChecked == true && param.columns.indexOf('offer_id') == -1) {
        param.columns.push('offer_id');
      } else if (this.offerChecked == false && param.columns.indexOf('offer_id') !== -1) {
        const offerIdIndex = param.columns.findIndex((item) => {
          if (item == 'offer_id') {
            return true;
          }
        });
        param.columns.splice(offerIdIndex, 1);
      }

      if (this.affSubsChecked == true && param.columns.indexOf('aff_sub') == -1) {
        param.columns.push('aff_sub');
      } else if (this.affSubsChecked == false && param.columns.indexOf('aff_sub') !== -1) {
        const affSubIndex = param.columns.findIndex((item) => {
          if (item == 'aff_sub') {
            return true;
          }
        });
        param.columns.splice(affSubIndex, 1);
      }

      if (this.monthChecked == true && param.columns.indexOf('month') == -1) {
        param.columns.push('month');
      } else if (this.monthChecked == false && param.columns.indexOf('month') !== -1) {
        const monthIndex = param.columns.findIndex((item) => {
          if (item == 'month') {
            return true;
          }
        });
        param.columns.splice(monthIndex, 1);
      }

      if (this.dateChecked == true && param.columns.indexOf('date') == -1) {
        param.columns.push('date');
      } else if (this.dateChecked == false && param.columns.indexOf('date') !== -1) {
        const dateIndex = param.columns.findIndex((item) => {
          if (item == 'date') {
            return true;
          }
        });
        param.columns.splice(dateIndex, 1);
      }

      if (this.hourChecked == true && param.columns.indexOf('hour') == -1) {
        param.columns.push('hour');
      } else if (this.hourChecked == false && param.columns.indexOf('hour') !== -1) {
        const hourIndex = param.columns.findIndex((item) => {
          if (item == 'hour') {
            return true;
          }
        });
        param.columns.splice(hourIndex, 1);
      }

      if (this.startToEndDate.length === 2) {
        let startDate = this.startToEndDate[0];
        let endDate = this.startToEndDate[1];
        param.fromDate = startDate;
        param.toDate = endDate;
      }

      if (this.defaultTraffic.defType == '') {
        this.defaultTraffic.defType = null;
      } else {
        param.defType = this.defaultTraffic.defType;
      }

      if (this.defaultTraffic.affiliateIds.length > 0) {
        param.affiliateIds = this.defaultTraffic.affiliateIds.join();
      }
      if (param.columns.length > 0) {
        param.columns = param.columns.join();
      }
      return param;
    },
    searchListClick(curPage) {
      this.listLoading = true;
      if (curPage) {
        this.currentPage = curPage;
      }
      const param = filterObject(this.getdefaultTrafficReportParam());
      getDefaultTrafficList(param)
        .then((response) => {
          this.listLoading = false;
          if (response.code === 200) {
            this.defaultTrafficList = response.result;
            this.pageCount = response.totalPage;
            this.totalCount = response.total;

            let totalObject = new Object();
            if (this.monthChecked || this.dateChecked || this.hourChecked) {
              totalObject.date = 'Total';
            }

            totalObject.affiliate_id = '';
            if (this.offerChecked) {
              totalObject.offer_id = '';
            }
            if (this.affSubsChecked) {
              totalObject.aff_sub = '';
            }
            totalObject.def_type = '';
            totalObject.count = this.totalCount;
            this.defaultTrafficList.push(totalObject);
          } else {
            this.$message.error(response.status.msg);
          }
        })
        .catch((e) => {
          console.log(e);
          this.listLoading = false;
          this.$$message.error(e);
        });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.searchListClick();
    },
    exportExcelFileClick() {},
    affiliatetailClick(row) {
      const { href } = this.$router.resolve({
        path: '/affiliate/detail',
        query: {
          affiliateId: row.affiliate_id,
        },
      });
      window.open(href, '_blank');
    },
    offerdetailClick(row) {
      const { href } = this.$router.resolve({
        path: '/offer/detail',
        query: {
          offerId: row.offer_id,
        },
      });
      window.open(href, '_blank');
    },
  },
};
