<template>
  <div class="defaultTraffic">
    <!-- <div class="main-Title bgff"><h2>Default Report</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="mb5 list-filter">
        <el-form v-model="defaultTraffic" size="mini">
          <el-row>
            <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
              <el-form-item label="OfferId(s)" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-input
                      v-model="defaultTraffic.offerIds"
                      placeholder="offerIds: eg. 2206264 or 2206264,1567396"
                    ></el-input>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox v-model="offerChecked"></el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
              <el-form-item label="AffiliateId(s)" label-width="90px" class="mb5">
                <group-select
                  v-model="defaultTraffic.affiliateIds"
                  :data-source="affiliatesGroupList"
                  multiple
                  :loading="affiliateLoading"
                  options
                  clearable
                  reserve-keyword
                  filterable
                  collapse-tags
                  class="w100"
                  size="mini"
                >
                </group-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
              <el-form-item label="Aff_Sub" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-input
                      v-model="defaultTraffic.affSubs"
                      class="w100"
                      placeholder="eg. carry_i or carry_i,carry_j"
                    ></el-input>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox v-model="affSubsChecked"></el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
              <el-form-item label="TimeZone" label-width="70px" class="mb5">
                <el-select v-model="defaultTraffic.timezone" class="w100">
                  <el-option
                    v-for="item in timezoneOption"
                    :key="item.value"
                    :value="item.number"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
              <el-form-item label="Target(s)" label-width="70px" class="mb5">
                <el-select v-model="defaultTraffic.defType" class="w100">
                  <el-option
                    v-for="item in targetOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6" style="height: 35px;">
              <el-form-item label="Interval" label-width="70px" class="mb5">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="Month" class="mb0">
                      <el-checkbox v-model="monthChecked"></el-checkbox>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="Date" class="mb0">
                      <el-checkbox v-model="dateChecked"></el-checkbox>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="Hour" class="mb0">
                      <el-checkbox v-model="hourChecked"></el-checkbox>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
              <el-form-item label="Date" label-width="60px" class="mb5">
                <el-date-picker
                  v-model="startToEndDate"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  class="w100"
                  align="right"
                  unlink-panels
                  range-separator="-"
                  start-placeholder="Start Date"
                  end-placeholder="End Date"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="16" :lg="16" :xl="6" align="right">
              <el-form-item class="mb0">
                <el-button type="primary" @click="searchListClick(1)">In Browser</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          v-loading="listLoading"
          class="w100"
          size="mini"
          stripe
          border
          :data="defaultTrafficList"
          highlight-current-row
        >
          <el-table-column label="Date" prop="date"></el-table-column>
          <el-table-column label="Affiliate ID">
            <template slot-scope="scope">
              <span class="cor337ab7" @click="affiliatetailClick(scope.row)">{{
                scope.row.affiliateId
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Offer ID" v-if="offerChecked" key="offerId">
            <template slot-scope="scope">
              <span class="cor337ab7" @click="offerdetailClick(scope.row)">{{
                scope.row.offerId
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Aff Sub"
            prop="affSub"
            v-if="affSubsChecked"
            key="affSub"
          ></el-table-column>
          <el-table-column label="Default Type" prop="defType"></el-table-column>
          <el-table-column label="Default Count" prop="count"></el-table-column>
        </el-table>
        <pagination
          class="block pagePanel"
          :pageTotal="pageCount"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="pageSize"
          :currentPage="currentPage"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>
<script>
  import DefaultTrafficReportCon from '../controllers/report/DefaultTrafficReport';

  export default {
    name: 'DefaultTrafficReport',
    ...DefaultTrafficReportCon,
  };
</script>
<style lang="scss" scoped></style>
<style lang="scss"></style>
